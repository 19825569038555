import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0005_Grants.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`
const TextHeading = styled.h3`
  font-family: ${props => props.theme.rift};
  font-size: 34px;
`
function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class GrantsPage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="gray"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>2018 Community Grant recipients included pump tracks, bike parks and protected bike lanes.</h4>,
          <Text key={ getRandomNumber(1,1000) }>PeopleForBikes Community Grants program funds infrastructure projects and a small number of advocacy campaigns focused on increasing the local investment in infrastructure. These grants are funded through support from Cannondale, Fuji, Giant, Shimano, Trek and individuals who work in the bike industry.</Text>,
          <Text key={ getRandomNumber(1,1000) }>We consistently receive hundreds of applications for each grant cycle; this year we awarded 10 grants in the spring cycle and eight in the fall. </Text>,
          <TextHeading key={ getRandomNumber(1,1000) }>Spring recipients</TextHeading>,
          <ul key={ getRandomNumber(1,1000) }>
            <li>KidsOnBikes: Colorado Springs, CO ($10,000)*</li>
            <li>BikeWalk NC: North Carolina ($10,000) </li>
            <li>Santa Cruz County: Santa Cruz County, CA ($10,000) </li>
            <li>Trailnet: St. Louis, MO ($10,000)  </li>
            <li>SORBA Tri-Cities Mountain Bike Club: Johnson City, TN ($10,000) </li>
            <li>Town of Johnson: Johnson, VT ($7,000) </li>
            <li>Teton Valley Trails and Pathways: Driggs, ID ($5,735)</li>
            <li>Bike Newton: Newton Center, MA ($4,000) </li>
            <li>City of Billings: Billings, MT ($2,500) </li>
            <li>United Gospel Rescue Mission: Poplar Bluff, MO ($1,000) </li>
          </ul>,
          <Text key={ getRandomNumber(1,1000) }>* REI partnered with PeopleForBIkes to support this project.</Text>,
          <TextHeading key={ getRandomNumber(1,1000) }><strong>Fall recipients</strong></TextHeading>,
          <ul key={ getRandomNumber(1,1000) }>
            <li>Cedar Trails Partnership: La Porte City, IA ($10,000)</li>
            <li>Cabo Rojo Bike and Hike Association, Inc.: Cabo Rojo, PR ($10,000)</li>
            <li>Mt. Washington Valley Trails Association; North Conway, NH ($6,500)</li>
            <li>West Point Community Foundation: West Point, NE ($6,500)</li>
            <li>Cook Inlet Housing Authority: Anchorage, AK ($6,000)</li>
            <li>Asheville on Bikes: Asheville, NC ($5,000)</li>
            <li>Bicycle Coalition of Greater Philadelphia; Philadelphia, PA ($4,000)</li>
            <li>Bike Walk Indian River County: Vero Beach, FL ($1,800)</li>
          </ul>,
          <Text key={ getRandomNumber(1,1000) }><a href="https://peopleforbikes.org/grants-awarded/" target="_blank" rel="noopener noreferrer">Read more about our grant awards here</a>.</Text>
        ]}
        button="Grants"
        imgalt="Backhoe image representing PeopleForBikes grants for bike infrastructure"
        imgsrc={ Hero }
        nextpage="/big-jump"
        previouspage="/meet-ride-spot"
        title="Directly investing in the best bike projects"
      />
    )
  }
}

export const draftQuery = graphql`
  query {
    one: file(relativePath: { eq: "story-images/PFB_Grants.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`






