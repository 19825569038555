import React, { Component } from 'react'
import styled from 'styled-components'

// Indiv Milestones
const MilestoneImgContainer = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 70px;
  display: flex;
  justify-content: center;
  height: 100px;
  margin: 0 auto;
  transition: 0.2s ease-in-out;
  width: 100px;

  &:hover {
    background-color: ${ props => props.theme.orange };
  }

  @media( min-width: 990px ) {
    height: 140px;
    width: 140px;
  }
`
const MilestoneImg = styled.img`
  display: block;
  filter: grayscale(1);
  height: 80px;
  transition: 0.2s ease-in-out;
  width: 80px;

  @media( min-width: 990px ) {
    height: 120px;
    width: 120px;
  }

  &:hover {
    filter: grayscale(0);
  }
`
const MilestoneText = styled.div`
  color: white;
  font-size: 11px;
  font-weight: ${ props => props.theme.fontBold };
  margin: 8px auto 0 auto;
  min-height: 32px;
  max-width: 100px;
  text-align: center;
  text-transform: uppercase;

  @media( min-width: 990px ) {
    font-size: 14px;
    min-height: 32px;
    max-width: 140px;
  }
`

export default class MilestoneIcon extends Component {
  render() {
    return (
      <div>
        <MilestoneImgContainer>
          <MilestoneImg src={ this.props.src } alt={ this.props.alt } />
        </MilestoneImgContainer>
        <MilestoneText>{ this.props.text }</MilestoneText>
      </div>
    )
  }
}