// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-better-bike-share-js": () => import("/opt/build/repo/src/pages/better-bike-share.js" /* webpackChunkName: "component---src-pages-better-bike-share-js" */),
  "component---src-pages-big-jump-js": () => import("/opt/build/repo/src/pages/big-jump.js" /* webpackChunkName: "component---src-pages-big-jump-js" */),
  "component---src-pages-bike-by-number-js": () => import("/opt/build/repo/src/pages/bike-by-number.js" /* webpackChunkName: "component---src-pages-bike-by-number-js" */),
  "component---src-pages-community-grants-js": () => import("/opt/build/repo/src/pages/community-grants.js" /* webpackChunkName: "component---src-pages-community-grants-js" */),
  "component---src-pages-congressional-bike-fest-js": () => import("/opt/build/repo/src/pages/congressional-bike-fest.js" /* webpackChunkName: "component---src-pages-congressional-bike-fest-js" */),
  "component---src-pages-draft-meetups-js": () => import("/opt/build/repo/src/pages/draft-meetups.js" /* webpackChunkName: "component---src-pages-draft-meetups-js" */),
  "component---src-pages-ebike-laws-js": () => import("/opt/build/repo/src/pages/ebike-laws.js" /* webpackChunkName: "component---src-pages-ebike-laws-js" */),
  "component---src-pages-fighting-tariffs-js": () => import("/opt/build/repo/src/pages/fighting-tariffs.js" /* webpackChunkName: "component---src-pages-fighting-tariffs-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meet-ride-spot-js": () => import("/opt/build/repo/src/pages/meet-ride-spot.js" /* webpackChunkName: "component---src-pages-meet-ride-spot-js" */),
  "component---src-pages-milestones-js": () => import("/opt/build/repo/src/pages/milestones.js" /* webpackChunkName: "component---src-pages-milestones-js" */),
  "component---src-pages-places-for-bikes-city-rating-js": () => import("/opt/build/repo/src/pages/places-for-bikes-city-rating.js" /* webpackChunkName: "component---src-pages-places-for-bikes-city-rating-js" */),
  "component---src-pages-vote-for-bikes-js": () => import("/opt/build/repo/src/pages/vote-for-bikes.js" /* webpackChunkName: "component---src-pages-vote-for-bikes-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

