import React, { Component } from 'react'
import { TimelineLite } from 'gsap'
import TransitionLink from 'gatsby-plugin-transition-link'
import Transition from 'react-transition-group/Transition'
import styled from 'styled-components'

import BaseLayout from '../components/BaseLayout'
import MilestoneIcon from '../components/Milestones/milestoneicon'

import menuBG1 from '../images/menu/PFB_menu_1.jpg'
import menuBG2 from '../images/menu/PFB_menu_2.jpg'
import menuBG3 from '../images/menu/PFB_menu_3.jpg'
import menuBG4 from '../images/menu/PFB_menu_4.jpg'
import menuBG5 from '../images/menu/PFB_menu_5.jpg'
import menuBG6 from '../images/menu/PFB_menu_6.jpg'
import menuBG7 from '../images/menu/PFB_menu_7.jpg'

import iconBikeShare from '../images/icons/PFB__0000_Bike-Share.png'
import iconContainers from '../images/icons/PFB__0001_Containers.png'
import iconBattery from '../images/icons/PFB__0002_Battery.png'
import iconChart from '../images/icons/PFB__0003_Chart.png'
import iconMidterm from '../images/icons/PFB__0004_Midterm.png'
import iconGrants from '../images/icons/PFB__0005_Grants.png'
import iconApp from '../images/icons/PFB__0006_App.png'
import iconCapitol from '../images/icons/PFB__0007_Capitol.png'
import iconMap from '../images/icons/PFB__0008_Map.png'
import iconDraft from '../images/icons/PFB__0009_Draft.png'
import iconSign from '../images/icons/PFB__0010_BigJump.png'

// Milestone Styles
const MilestoneHeader = styled.h1`
  color: white;
  font-family: 'flood-std', Helvetica, Arial, sans-serif;
  font-size: 48px;
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;

  @media (min-width: 700px) {
    font-size: 64px;
    padding: 35px 0;
  }
`

// Random bg generator for MilestoneContainer
const MilestoneBackgrounds = [
  menuBG1,
  menuBG2,
  menuBG3,
  menuBG4,
  menuBG5,
  menuBG6,
  menuBG7
]
const randomMilestoneBackground = MilestoneBackgrounds[Math.floor(Math.random() * MilestoneBackgrounds.length)];

const MilestoneContainer = styled.section`
  align-items: center;
  background-image: url('${ randomMilestoneBackground }');
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 110px);
  opacity: 0;

  @media( min-width: 480px ) {
    height: calc(100vh - 90px);
  }
`
const MilestoneCenter = styled.div``
const MilestoneContentContainer = styled.main``
const MilestoneIconSet = styled.section`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

  @media( min-width: 480px ) and ( max-width: 700px) {
    max-width: 480px;
  }

  @media( min-width: 700px ) and ( max-width: 990px) {
    max-width: 690px;
  }

  @media( min-width: 990px ) {
    max-width: 970px;
  }
`
const MilestoneLink = styled(TransitionLink)`
  margin: 5px;
  text-decoration: none;

  @media( min-width: 990px ) {
    margin: 10px;
  }
`

export default class NavPage extends Component {
  constructor(props) {
    super(props)

    // Sets up binding for TransitionLink and creates null reference
    this.layoutContents = React.createRef()
    this.transitionCover = React.createRef()

    // Sets up fadeIn / fadeOut - must bind
    this.intro = this.intro.bind(this)
    this.outro = this.outro.bind(this)
    this.toggleShowState = this.toggleShowState.bind(this);

    // You have to set each element you want to null so gsap can wait for react to do it's magic
    this.layoutContents = null

    this.state = {
      show: true // for Transition RTG, set to true because we want to trigger on component mount
    };
  }
  
  // Turns the show state false, triggers exit
  toggleShowState() {
    this.setState({ 
      show: false 
    });
  }

  // After the component mounts, we can attach the gsap animation stuff in
  intro = () => {
    return new TimelineLite()
      .set(this.layoutContents, { display: 'flex' })
      .to(this.layoutContents, 0.6, { 
        autoAlpha: 1
      }, 0.6)
  }
  outro = () => {
    return new TimelineLite()
      .set(this.layoutContents, { display: 'flex' })
      .to(this.layoutContents, 0.5, { 
        autoAlpha: 0,
      })
  }

  render() {
    return (
      <BaseLayout>
        <Transition
          timeout={ 500 }
          appear={ true }
          in={ this.state.show }
          mountOnEnter={ true }
          onEntering={ 
            () => { this.intro() } 
          }>

          <MilestoneContainer ref={n => (this.layoutContents = n)}>
            <MilestoneCenter>
              <MilestoneContentContainer>
                <MilestoneHeader>Milestones</MilestoneHeader>
                <MilestoneIconSet>

                  {/* 1 - E-bikes */}
                  <MilestoneLink 
                    to="/ebike-laws" 
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="Standardizing ebike laws"
                      src={ iconBattery }
                      alt="battery icon"
                    />
                  </MilestoneLink>  

                  {/* 2 - Places for Bikes */}
                  <MilestoneLink
                    to="/places-for-bikes-city-rating"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="PlacesForBikes City Ratings"
                      src={ iconMap }
                      alt="map icon"
                    />
                  </MilestoneLink>

                  {/* 3- DRAFT */}
                  <MilestoneLink
                    to="/draft-meetups"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="DRAFT Meetups"
                      src={ iconDraft }
                      alt="draft beer icon"
                    />
                  </MilestoneLink>

                  {/* 4 - Midterms */}
                  <MilestoneLink
                    to="/vote-for-bikes"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="VoteForBikes"
                      src={ iconMidterm }
                      alt="voting lapel pin icon"
                    />
                  </MilestoneLink>           

                  {/* 5- Bike By Number */}
                  <MilestoneLink
                    to="/bike-by-number"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="Bikes by the Numbers"
                      src={ iconChart }
                      alt="pie chart icon"
                    />
                  </MilestoneLink>   

                  {/* 6- Ride Spot App */}
                  <MilestoneLink
                    to="/meet-ride-spot"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="Meet Ride Spot"
                      src={ iconApp }
                      alt="phone app icon"
                    />
                  </MilestoneLink>                      

                  {/* 7 - Community Grants */}
                  <MilestoneLink
                    to="/community-grants"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="Community Grants"
                      src={ iconGrants }
                      alt="backhoe icon"
                    />
                  </MilestoneLink>   

                  {/* 8 - Big Jump */}
                  <MilestoneLink
                    to="/big-jump"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="Big Jump"
                      src={ iconSign }
                      alt="bike sign icon"
                    />
                  </MilestoneLink>

                  {/* 9 - Congressional Bike Fest */}
                  <MilestoneLink
                    to="/congressional-bike-fest"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="Congressional Bike Fest"
                      src={ iconCapitol }
                      alt="captiol icon"
                    />
                  </MilestoneLink>                  

                  {/* 10 - Tariffs */}
                  <MilestoneLink
                    to="/fighting-tariffs"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="Fighting Tariffs"
                      src={ iconContainers }
                      alt="shipping containers icon"
                    />
                  </MilestoneLink>     

                  {/* 11 - Bike Share */}
                  <MilestoneLink
                    to="/better-bike-share"
                    exit={{
                      length: 1.6,
                      trigger: () => this.outro(),
                    }}
                    entry={{ 
                      delay: 1, 
                      length: 1
                    }}>
                    <MilestoneIcon
                      text="Better Bikeshare"
                      src={ iconBikeShare }
                      alt="bike share station icon"
                    />
                  </MilestoneLink>                                             

                </MilestoneIconSet>
              </MilestoneContentContainer>
            </MilestoneCenter>
          </MilestoneContainer>
        </Transition>
      </BaseLayout>
    )
  }
}