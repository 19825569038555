import React from 'react'
import { ThemeProvider } from 'styled-components'
import styled from 'styled-components'

import { Variables, Globals } from './styles'
import Header from './header'
import Metadata from './metadata';

// Offsets the fixed header
const SiteContainer = styled.main`
  display: block;
`

// BaseLayout component
export default class BaseLayout extends React.Component {

  render() {
    if (typeof window !== `undefined` && window.location.pathname === "/") {
      return (
        <SiteContainer>
          <Metadata />
          <ThemeProvider theme={ Variables }>
            <div>
              <Globals></Globals>
              <div>
                { this.props.children }
              </div>
            </div>
          </ThemeProvider>
        </SiteContainer>
      )
    } else {
      return (
        <SiteContainer>
          <Metadata />
          {
            /** 
              * You need to wrap the children of ThemeProvider in a div, so you aren't returning an array
              * See more here: https://stackoverflow.com/questions/46221555/react-js-react-children-only-expected-to-receive-a-single-react-element-child
              */
          }
          <ThemeProvider theme={ Variables }>
            <div>
              <Globals></Globals>
              <Header />
              <div>
                { this.props.children }
              </div>
            </div>
          </ThemeProvider>
        </SiteContainer>
      )      
    }
  }
}  