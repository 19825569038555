import React from 'react'
import styled from 'styled-components'

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0001_Containers.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class TariffsPage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="blue"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>More than 700,000 U.S. jobs are supported by bicycling. These industry voices are a powerful force that help advance pro-bike policies and fight legislation that would hurt bike riding.</h4>,
          <Text key={ getRandomNumber(1,1000) }>In 2018, combating higher tariffs on bicycle products imported from China was a major focus of our policy work. PeopleForBikes organized and led an industry effort to oppose tariff increases on bikes, components and accessories.</Text>,
          <Text key={ getRandomNumber(1,1000) }>Through our advocacy alert program, we spurred <strong>7,776 letters to Congress</strong> and <strong>976 industry-generated comments</strong> to the U.S. Trade Representative. We also prepared 10 bike executives to testify in Washington, D.C. and share their personal stories of how tariff increases on bike products would harm the industry and potentially compromise rider safety.</Text>,
          <Text key={ getRandomNumber(1,1000) }>Read more about our tariff work <a href="https://peopleforbikes.org/tariffs/" target="_blank" rel="noopener noreferrer">here</a>.</Text>,
          <Text key={ getRandomNumber(1,1000) }>We also sent alerts to industry members and bike supporters to connect them with more than 25 other state and local policy initiatives, including: </Text>,
          <ul key={ getRandomNumber(1,1000) }>
            <li>Fighting high-visibility clothing requirements for bikers in Iowa</li>
            <li>Keeping governments involved in Florida bikeshare programs.</li>
            <li>Encouraging safe passing laws in Hawaii.</li>
            <li>Supporting safer Pennsylvania streets by helping pass a speed camera pilot program.</li>
            <li>Preserving protected bike lanes in Manhattan.</li>
          </ul>,
          <Text key={ getRandomNumber(1,1000) }>Can we help support for a bike-related issue in your area? <a href="https://peopleforbikes.org/local-engagement-portal/" target="_blank" rel="noopener noreferrer">Let us know</a>.</Text>
        ]}
        button="Policy"
        imgalt="Backhoe representing the tariff fight"
        imgsrc={ Hero }
        nextpage="/better-bike-share"
        previouspage="/congressional-bike-fest"
        title="Defending bike businesses"
      />
    )
  }
}