import React from 'react'
import styled from 'styled-components'

import Milestone from '../components/Milestones'
import Vote from '../images/main-art/PFB__0004_Midterm.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class VotePage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="red"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>With our help, places for bikes won big in the 2018 midterm elections: Voters approved $2 billion in new bike infrastructure investments for the next decade. </h4>,
          <Text key={ getRandomNumber(1,1000) }>Leading up to the November midterm elections, we helped inform voters on 11 measures with the potential to directly affect bike riding. The biggest win came in California, where voters cast their ballots against Proposition 6. The failure of Prop 6 preserved $100 million in annual bike infrastructure funding. We supported state advocacy group CalBike by reaching out to more than 60,000 of our California members and helping them understand how Prop 6, if passed, would hurt bike funding.</Text>,
          <Text key={ getRandomNumber(1,1000) }>Other wins included the passing of Georgia’s <strong>Outdoor Stewardship amendment</strong>, with 80% of voters in favor. This amendment directs the existing sales tax on outdoor goods (including bikes) to parks, trails and conservation. </Text>,
          <Text key={ getRandomNumber(1,1000) }>Bond measures to fund parks and transportation — <strong>including bike lanes, greenways and trails</strong> — passed by large margins in Austin, Texas (81% of voters in favor), and Rhode Island (79% of voters in favor).</Text>,
          <Text key={ getRandomNumber(1,1000) }>These successful state and city funding campaigns demonstrated the political power of our 1.3 million citizen supporters.</Text>,
          <Text key={ getRandomNumber(1,1000) }>View our <a href="https://peopleforbikes.org/2018-ballot-tracker/" target="_blank" rel="noopener noreferrer">2018 Ballot Tracker</a> to read about all 10 bike victories.</Text>
        ]}
        button="Advocacy"
        imgalt="Election pins representing PeopleForBikes work in driving pro-bike legislation during the 2018 US midterm elections"
        imgsrc={ Vote }
        nextpage="/bike-by-number"
        previouspage="/draft-meetups"
        title="#VoteForBikes"
      />
    )
  }
}