import React from 'react'
import { Link, graphql } from 'gatsby'

import { TimelineLite } from "gsap/all"
import Transition from 'react-transition-group/Transition'

import BaseLayout from '../components/BaseLayout'

import logo from '../images/pfb-logo.png'

export default class IndexPage extends React.Component {
  // State engine
  constructor(props){
    super(props);
    
    this.state = {
      intervalId: 0, // For scrollTop
      show: true // for Transition RTG, set to true because we want to trigger on component mount
    };

    // You have to set each element you want to null so gsap can wait for react to do it's magic
    this.intropagecontainer = null
		this.intrologo = null
    this.introtext = null
    this.intronav = null
    
    // Binds toggleShowState to this - helps trigger the Transition component
    this.toggleShowState = this.toggleShowState.bind(this);
    this.animateLoad = this.animateLoad.bind(this);
  }

  // Turns the show state false, triggers exit
  toggleShowState() {
    this.setState({ 
      show: false 
    });
  }

  animateLoad() {
    const animateLoad = new TimelineLite()
    
    animateLoad.set(
      this.intropagecontainer, { 
        display: 'block' 
      }).from(
      this.intropagecontainer, 0.7, { 
        autoAlpha: 1,
      }, 0.2).from(
      this.introtext, 0.7, { 
        autoAlpha: 1,
      }, 0.3).from(
      this.intronav, 0.7, {
        autoAlpha: 1,
      }, 0.4).play()
  }

  render() {    
    return (  
      <BaseLayout>
        <Transition
          timeout={ 100 }
          appear={ true }
          in={ this.state.show }
          mountOnEnter={ true }
          onEntering={ 
            () => { this.animateLoad() } 
          }>
          <div>
            <main className="intropagecontainer" ref={ main => this.intropagecontainer = main }>
              <div className="introimage introimageone"></div>
              <div className="introimage introimagetwo"></div>
              <div className="introimage introimagethree"></div>
              <div className="introimage introimagefour"></div>
            </main>
            <img className="intrologo" ref={ img => this.intrologo = img }
              src={ logo } 
              alt="People for Bikes Logo" 
            />
            <div className="introtext" ref={ div => this.introtext = div }>
              <h1 className="introtitle">Making biking better for everyone</h1>
              <hr className="introrule" />
              <div className="introdeck">
                <h2 className="introsub introsubone">$88 billion in U.S. economic activity is generated by bicycling.</h2>
                <h2 className="introsub introsubtwo">Annual U.S. bikeshare rides have increased from 500,000 to 35 million during the last eight years.</h2>
                <h2 className="introsub introsubthree">786,663 U.S. jobs are supported by bicycling.</h2>
                <h2 className="introsub introsubfour">Nearly one in three Americans rode a bike last year.</h2>
              </div>
            </div>
            
            <div className="intronav" ref={ div => this.intronav = div }>
              <Link to="/milestones">Jump to Main Menu</Link>
            </div>
          </div>
        </Transition>
      </BaseLayout>
    )
  }
} 

export const introQuery = graphql`
  query {
    introOne: file(relativePath: { eq: "intro/PFB_INTRO_IMAGES_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    introTwo: file(relativePath: { eq: "intro/PFB_INTRO_IMAGES_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    introThree: file(relativePath: { eq: "intro/PFB_INTRO_IMAGES_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    introFour: file(relativePath: { eq: "intro/PFB_INTRO_IMAGES_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`