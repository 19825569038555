import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0000_Bike-Share.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class BikesharePage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="red"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>Efforts to increase access and use of bikeshare in low-income communities and communities and companies of color have grown with the introduction of dockless bikeshare, electric bikes and scooters.</h4>,
          <Text key={ getRandomNumber(1,1000) }>The <a href="http://betterbikeshare.org/" target="_blank" rel="noopener noreferrer">Better Bike Share Partnership</a> is a collaboration funded by <a href="http://www.jpbfoundation.org/" target="_blank" rel="noopener noreferrer">The JPB Foundation</a> to build equitable and replicable bike share systems. The partners include PeopleForBikes Foundation, <a href="http://www.phila.gov/Pages/default.aspx" target="_blank" rel="noopener noreferrer">The City of Philadelphia</a>, <a href="http://bicyclecoalition.org/" target="_blank" rel="noopener noreferrer">Bicycle Coalition of Greater Philadelphia</a>, and the <a href="http://nacto.org/ " target="_blank" rel="noopener noreferrer">National Association of City Transportation Officials</a>.</Text>,
          <Image key={ getRandomNumber(1,1000) } fluid={ this.props.data.one.childImageSharp.fluid } />,
          <Text key={ getRandomNumber(1,1000) }>In 2018, PeopleForBikes awarded a third round of grants totaling more than $260,000 to a partnership of cities, operators and community-based organizations in six cities: Charleston, South Carolina; Chicago; Detroit; Ithaca, New York; New Orleans; and Pittsburgh. We also awarded two research grants to Drexel University and Portland State University. </Text>,
          <Text key={ getRandomNumber(1,1000) }>In September, the Better Bike Share Partnership worked with the North American Bikeshare Association on an annual conference, held in Portland, Oregon. The conference attracted more than 350 attendees from across the U.S. and around the world. </Text>,
          <Text key={ getRandomNumber(1,1000) }>The week’s events focused on including diverse voices on panels and bringing an equity focus to seminars, workshops and keynotes. The conference included an array of panel discussions, workshops and social events, giving an opportunity for attendees to listen, learn, ask questions and engage with experts in mobility justice, communicating for racial equity and representing diversity through content creation and marketing. </Text>,
          <Text key={ getRandomNumber(1,1000) }>Read more at: <a href="http://betterbikeshare.org/" target="_blank" rel="noopener noreferrer">http://betterbikeshare.org/</a>.</Text>
        ]}
        button="Equity"
        imgalt="Vector bike share station image"
        imgsrc={ Hero }
        nextpage="/ebike-laws"
        previouspage="/fighting-tariffs"
        title="Building better bikeshare for all"
      />
    )
  }
}

export const draftQuery = graphql`
  query {
    one: file(relativePath: { eq: "story-images/PFB_BikeShare.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`