import React from 'react'
import styled from 'styled-components'

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0002_Battery.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class EbikesPage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="gray"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>In 2018, Arizona, Connecticut, Ohio and Washington all passed e-bike laws, making a total of 11 states to adopt PeopleForBikes’ model legislation. </h4>,
          <Text key={ getRandomNumber(1,1000) }>Given that e-bikes are relatively new to the bike market, many states have yet to develop clear guidelines on where they can and can’t be ridden. We’re four years into a campaign to standardize e-bike laws in all 50 states and clarify e-bikes definitions using the industry-adopted three-class system.</Text>,
          <Text key={ getRandomNumber(1,1000) }>We’re also working with the local, state and federal land management agencies (including the Bureau of Land Management, U.S. Forest Service and National Park Service) to ensure that guidelines for electric mountain bike use on federal lands reflect modern technology. </Text>,
          <Text key={ getRandomNumber(1,1000) }>Read more e-bike policies and laws <a href="https://peopleforbikes.org/our-work/e-bikes/" target="_blank" rel="noopener noreferrer">on our site</a>.</Text>
        ]}
        button="Policy"
        imgalt="Battery icon representing ebikes"
        imgsrc={ Hero }
        nextpage="/places-for-bikes-city-rating"
        previouspage="/better-bike-share"
        title="Improving e-bike laws, one state at a time"
      />
    )
  }
}