import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0010_BigJump.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

const TextHeading = styled.h3`
  font-family: ${props => props.theme.rift};
  font-size: 34px;
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class BigJumpPage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="blue"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>Ten U.S. cities strive to make leaps in bike infrastructure.</h4>,
          <Text key={ getRandomNumber(1,1000) }>In 2018, the Big Jump project completed its second year. The program provides resources in 10 designated U.S. cities to quickly increase bike use. The goals are to rapidly expand local public outreach, build technical design and community advocacy capabilities and accelerate implementation of a complete bike network in one neighborhood or city area.</Text>,
          <Image key={ getRandomNumber(1,1000) } fluid={ this.props.data.one.childImageSharp.fluid } />,
          <Text key={ getRandomNumber(1,1000) }>Through this support, the ultimate aim is to double or triple bicycle use in each project site by the end of a three-year period. Click on a “Read More” option below to learn more about the progress of a city’s project.</Text>,
          <TextHeading key={ getRandomNumber(1,1000) }>Big Jump cities</TextHeading>,
          <ul key={ getRandomNumber(1,1000) }>
            <li>Austin, Texas</li>
            <li>Baltimore, Maryland » <a href="https://peopleforbikes.org/blog/bikes-score-in-baltimore/" target="_blank" rel="noopener noreferrer">READ MORE</a></li>
            <li>Fort Collins, Colorado » <a href="https://peopleforbikes.org/blog/fort-collins-programing-shines/" target="_blank" rel="noopener noreferrer">READ MORE</a></li>
            <li>Los Angeles, California</li>
            <li>Memphis, Tennessee » <a href="https://peopleforbikes.org/placesforbikes/european-strategies-on-rural-memphis-streets/" target="_blank" rel="noopener noreferrer">READ MORE</a></li>
            <li>New Orleans, Louisiana » <a href="https://peopleforbikes.org/blog/new-orleans-growing-bike-culture/" target="_blank" rel="noopener noreferrer">READ MORE</a></li>
            <li>New York (Queens), New York</li>
            <li>Portland, Oregon</li>
            <li>Providence, Rhode Island » <a href="https://peopleforbikes.org/placesforbikes/providence-rhode-island-is-on-the-move/" target="_blank" rel="noopener noreferrer">READ MORE</a></li>
            <li>Tucson, Arizona</li>
          </ul>
        ]}
        button="City Resources"
        imgalt="Bike street sign representing big jump for people for bikes"
        imgsrc={ Hero }
        nextpage="/congressional-bike-fest"
        previouspage="/community-grants"
        title="Supporting big jumps in city infrastructure"
      />
    )
  }
}

export const draftQuery = graphql`
  query {
    one: file(relativePath: { eq: "story-images/PFB_BigJump.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`