import { createGlobalStyle } from 'styled-components'

import introBG1 from '../../images/intro/PFB_INTRO_IMAGES_01.jpg'
import introBG2 from '../../images/intro/PFB_INTRO_IMAGES_02.jpg'
import introBG3 from '../../images/intro/PFB_INTRO_IMAGES_03.jpg'
import introBG4 from '../../images/intro/PFB_INTRO_IMAGES_04.jpg'

/** 
 * styled-components global stylesheet
 * This gets a little wonky by CSS-in-JS standards
 * Surprisingly, works a lot like a regular one
 * 
 * Make one object of all the variables
 * Then make a createGlobalStyle setup for all your globals
 * You can reference the variables in the global with Variable.whatever
 * 
 * Set global variables the whole application can access when building styled-components
 * You can access them with the props.theme object passed by ThemeProvider 
 * 
 *  var Nav = styled.nav`
 *    color: ${props => props.theme.darkGray};
 *  `
 * 
 * Good overview of this approach:
 * https://alligator.io/react/theming-styled-components/
 * 
 */


// CSS Variables with styled-components
export const Variables = {
  black: 'rgb(12,12,12)',
  darkGray: 'rgb(90,90,90)',
  mediumGray: 'rgb(101,101,101)',
  lightGray: 'rgb(200,200,200)',
  lightestGray: 'rgb(235,225,225)',
  darkBlue: 'rgb(0.99.148)',
  lightBlue: 'rgb(0,143,213)',
  lightestBlue: 'rgb(131,214,255)',
  red: 'rgb(235,27,35)',
  orange: 'rgb(246,165,35)',

  proximaNova: '"proxima-nova", Helvetica, Arial, sans-serif',
  rift: '"rift", Helvetica, Arial, sans-serif',

  fontLight: '300',
  fontRegular: '400',
  fontBold: '700',

  ty: '380px',
  xs: '480px',
  sm: '768px',
  md: '980px',
  lg: '1200px',
  xl: '1600px',

  zIndexNegative: '-10',
  zIndex00: '0',
  zIndex01: '10',
  zIndex02: '20',
  zIndex03: '30',
  zIndex04: '40',
  zIndex05: '50',
  zIndex06: '60',
  zIndex07: '70',
  zIndex08: '80',
  zIndex09: '90'
}

/* Put all your global styles into this thing, just normal CSS */
export const Globals = createGlobalStyle`
  body {
    background-color: #fff;
  }
  html {
    font-family: ${ Variables.proximaNova };
    font-size: 18px;
    font-weight: ${ Variables.fontLight };
  }
  p {
    margin: 15px 0;
  }
  h1 {
    font-family: ${ Variables.proximaNova };
    font-size: 42px;
    font-weight: ${ Variables.fontLight };
  }
  h2, h3 {
    font-family: ${ Variables.rift };
    font-size: 34px;
  }
  h4 {
    font-style: italic;
    font-weight: 300;
    line-height: 1.8;
    font-size: 18px;
    margin: 1em 0;

    @media (min-width: ${ Variables.xs } ) {
      font-size: 22px;
    }
  }
  li {
    padding: 5px 0;
  }
  a, a:hover, a:visited, a:focus {
    color: ${ Variables.lightBlue };
  }
  .red {
    background-color: ${ Variables.red };
  }
  .blue {
    background-color: ${ Variables.lightBlue };
  }
  .gray {
    background-color: ${ Variables.darkGray };
  }

  /* Hero */
  .introcontainer {
    height: 80vh;
  }
  .herobackground {
    height: 60vh;
  }
  .herotext {
    display: block;
    @media (min-width: ${ Variables.lg }) {
      margin-bottom: 10vh;
    }
  }
  .heroimage {
    display: block;
    height: 45vh;
    @media (min-width: ${ Variables.sm }) and (max-width: ${ Variables.lg }) {
      height: 50vh;
      margin-right: -5vh;
    }
    @media (min-width: ${ Variables.lg }) {
      height: 60vh;
      margin-right: -10vh;
    }
  }
  .herotitle {
    color: white;
    font-size: 40px;
    line-height: 1;
    margin: 15px auto 25px auto;
    max-width: 400px;
    padding: 0 25px;
    text-align: center;
    @media( min-width: ${ Variables.sm } ) and ( max-width: ${ Variables.md } ) {
      font-size: 45px;
      margin: 15px -30vw 10vh 0;
      max-width: 300px;
      padding: 0;
      text-align: left;
    }
    @media( min-width: ${ Variables.md } ) and ( max-width: ${ Variables.lg } ) {
      font-size: 55px;
      margin: 15px -10vw 10vh 0;
      padding: 0;
      text-align: left;
    }
    @media( min-width: ${ Variables.lg } ) {
      font-size: 55px;
      margin: 15px 0 0 0;
      padding: 0;
      text-align: left;
    }
  }
  .herobutton {
    border: 1px solid white;
    color: white;
    display: block;
    margin: 25px auto;
    max-width: 200px;
    padding: 6px 12px;
    text-align: center;
    text-transform: uppercase;
    @media( min-width: ${ Variables.sm } ) {
      display: inline;
      margin: 0;
      text-align: left;
    }
  }
  .bodycontainer {
    margin: -10vh 10vw 10vh 10vw;
    max-width: 768px;
    overflow: auto; /* lets the last p margin be shown */

    @media( min-width: ${ Variables.sm } ) and ( max-width: ${ Variables.md } ) {
      margin: -15vh 10vw 10vh 10vw;
    }
    @media( min-width: ${ Variables.md } ) and ( max-width: ${ Variables.lg } ) {
      margin: -10vh auto 10vh auto;
    }
    @media( min-width: ${ Variables.lg } ) {
      margin: -10vh auto 10vh auto;
    }
  }

  /* intro page container stuff*/
  .intropagecontainer {
    overflow: hidden;
    position: relative;
    margin: 0;
    height: 100vh;
    width: 100%;
  }
  .introimage {
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -25px;
  }
  .introimageone { 
    animation: scaler 10s ease-in-out infinite alternate, backgroundChange 32s cubic-bezier(0.690, 0.200, 0.305, 0.760) 24s infinite;
    background-image: url(${ introBG4 }); 
  }
  .introimagetwo { 
    animation: scaler 10s ease-in-out infinite alternate, backgroundChange 32s cubic-bezier(0.690, 0.200, 0.305, 0.760) 16s infinite;
    background-image: url(${ introBG3 }); 
  }
  .introimagethree { 
    animation: scaler 10s ease-in-out infinite alternate, backgroundChange 32s cubic-bezier(0.690, 0.200, 0.305, 0.760) 8s infinite;
    background-image: url(${ introBG2 }); 
  }
  .introimagefour { 
    animation: scaler 10s ease-in-out infinite alternate, backgroundChange 32s cubic-bezier(0.690, 0.200, 0.305, 0.760) infinite;
    background-image: url(${ introBG1 }); 
  }

  @keyframes scaler {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.05);
    }
  }

  @keyframes backgroundChange {
    3% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .intrologo {
    position: absolute;
    top: 21px;
    left: 25px;
    width: 212px;
  }
  .intronav {
    bottom: 21px;
    position: absolute;
    right: 25px;

    a, a:visited, a:focus, a:hover {  
      color: #fff;
      font-family: ${ Variables.proximaNova };
      font-size: 16px;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
  .introtext {
    color: #fff;
    margin: 0 auto;
    position: absolute;    
    text-align: center;
    left: 0;
    padding: 0 25px;
    top: 50%;
    transform: translateY(-50%);
  
    @media (min-width: 600px) and (max-width: ${ Variables.sm }) {
      left: 50%;
      min-width: 550px;
      padding: 0;
      transform: translateX(-50%) translateY(-50%);
    }

    @media (min-width: ${ Variables.sm }) {
      left: 50%;
      width: 650px;
      padding: 0;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .introrule {
    margin: 15px auto;
    max-width: 350px;
  }
  .introtitle {
    color: #fff;
    font-family: 'flood-std',Helvetica,Arial,sans-serif;
    font-size: 50px;
    line-height: 1;
    margin: 0;
    text-align: center;

    @media (min-width: ${ Variables.sm }) {
      font-size: 60px;
    }
  }
  .introdeck {
    position: relative;
    min-height: 60px;
  }
  .introsub {
    position: absolute;
    color: #fff;
    font-size: 24px;
    text-align: center;
    top: 0;
    width: 100%;
  }

  .introsubone {
    animation: fade-in-then-out 32s linear infinite;
    opacity: 0;
  }
  .introsubtwo {
    animation: fade-in-then-out 32s linear 8s infinite;
    opacity: 0;
  }
  .introsubthree {
    animation: fade-in-then-out 32s linear 16s infinite;
    opacity: 0;
  }
  .introsubfour {
    animation: fade-in-then-out 32s linear 24s infinite;
    opacity: 0;
  }

  @keyframes fade-in-then-out {
    3% {
      opacity: 1;
    }
    23% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  /* Animation */
  .fade-in {
    animation: fade-in 0.2s forwards;
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fade-in-up {
    animation: fade-in-up 1.5s forwards;
  }
  @keyframes fade-in-up {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  .fade-out-down {
    animation: fade-out-down 1.5s forwards;
  }
  @keyframes fade-out-down {
    from {
      opacity: 1;
      transform: translateY(0px);
    }
    to {
      opacity: 0;
      transform: translateY(50px);
    }
  }
  .fade-in-from-right-delayed {
    animation: fade-in-from-right 1s forwards;
    animation-delay: 500ms;
  }
  @keyframes fade-in-from-right-delayed {
    from {
      opacity: 0;
      transform: translateX(60px);
    }
    to {
      opacity: 1 !important;
      transform: translateX(0px);
    }
  }
  .fade-in-from-left-delayed {
    animation: fade-in-from-left 1s forwards;
    animation-delay: 500ms;
  }
  @keyframes fade-in-from-left-delayed {
    from {
      opacity: 0;
      transform: translateX(-60px);
    }
    to {
      opacity: 1 !important;
      transform: translateX(0px);
    }
  }

  /* Hero background styles */
  .fade-in-from-right {
    animation: fade-in-from-right 1s forwards;
  }
  @keyframes fade-in-from-right {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1 !important;
      transform: translateX(0px);
    }
  }

/* Footer */
/* Signup */
.pfb-signup {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid darkgray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10% 5%;

  @media (min-width: ${ Variables.xs }) {
    padding: 5%;
  }
}

.pfb-signup-logo {
  border: 3px solid #fff;
  border-radius: 15px;
  margin-top: 30px;
  width: 90px;

  @media(min-width: ${ Variables.xs }) {
    width: 120px;
  }
}

.pfb-signup-title {
  color: black;
  font-family: 'flood-std',Helvetica,Arial,sans-serif;
  font-size: 70px;
  line-height: 1;
  margin: 25px 0 15px 0;
  text-align: center;

  @media (min-width: ${ Variables.xs }) {
    font-size: 90px;
  }
}

.pfb-signup-text {
  color: black;
  font-size: 24px;
  line-height: 1.5;
  margin: 6px;
  width: 280px;
  text-align: center;

  @media (min-width: ${ Variables.xs }) and (max-width: ${ Variables.sm }) {
    width: 350px;
  }

  @media (min-width: ${ Variables.sm }) {
    width: 500px;
  }

  span {
    display: inline;

    @media (min-width: ${ Variables.sm }m) {
      display: block;
      margin-bottom: 10px;
    }
  }
}

.pfb-signup-text:first-child {
  margin-top: 0;
}

.sign-up-group {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${ Variables.sm }) {
    flex-direction: row;
  }
}

/* Corrects for alignment issues, fits into grid more tightly */
.sign-up-group-last {
  @media (min-width: ${ Variables.sm }) {
    margin-top: -15px;
  }
}

/* FORM
 * this holds the form inside of it, must mirror #pfb-signup-submission size
 */
.pfb-signup-form-container {
  height: 390px;
  position: relative;
  width: 280px;

  @media (min-width: ${ Variables.xs }) and (max-width: ${ Variables.sm }) {
    height: 390px;
    width: 340px;
  }

  @media (min-width: ${ Variables.sm }) {
    height: 250px;
    width: 700px;
  }
}

.pfb-signup-form {
  padding-bottom: 35px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${ Variables.zIndex02 };
}

.pfb-footer-bar {
  height: 1px;
  background-color: #fff;
  width: 260px;
  margin: 0 auto;

  @media (min-width: ${ Variables.ty }) and (max-width: ${ Variables.sm }) {
    width: 320px;
  }

  @media (min-width: ${ Variables.sm }) {
    width: 700px;
  }
}

input.pfb-signup-box {
  background-color: white;
  border: 1px solid darkgrey;
  color: black;
  font-size: 18px;
  margin: 15px auto 0 auto;
  display: inline-block;
  height: 40px;
  width: 260px;
  padding: 10px;
  text-transform: uppercase;

  /* There is no set syntax for styling placeholder color, so this zoo is here
   * Opacity is required for mozilla for some reason
   * More here: https://css-tricks.com/almanac/selectors/p/placeholder/
   */
  &::placeholder {
    color: rgba(0,0,0,0.6);
  }

  &::-webkit-input-placeholder {
    color: rgba(0,0,0,0.6);
  }

  &::-moz-placeholder {
    color: rgba(0,0,0,0.6);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: rgba(0,0,0,0.6);
  }

  @media (min-width: ${ Variables.xs }) {
    padding: 10px 20px;
    width: 300px;
  }

  @media (min-width: ${ Variables.sm }) {
    margin: 15px 7.5px;
  }
}

input[type=submit] {
  background-color: ${ Variables.red };
  border: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: bold;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  padding: 12px;
  text-transform: uppercase;
  width: 280px;

  &:hover {
    background-color: ${ Variables.red };
    transition: .2s ease-in-out;
  }

  @media (min-width: ${ Variables.xs }) {
    width: 340px;
  }
}

.pfb-signup-success-text {
  color: #000;
  font-size: 24px;
  line-height: 1.5;
  margin: 0 auto;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 35%;
  text-align: center;
  width: 100%;
  z-index: ${ Variables.zIndex01 };
}

.pfb-signup-hidden {
  display: none;
}

/* Bottom link text */
.pfb-site-link-container {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
  color: black;
  margin: 25px 0 0 0; /* bottom margin comes from padding of footer container */
  padding: 0 15px;
  text-align: center;

  a, a:visited, a:hover, a:focus {
    color: ${ Variables.red };
  }
}

/* Form animations */
.hide-form {
  animation: hide-form 0.5s forwards;
  animation-delay: 0.25s;
}

@keyframes hide-form {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.show-form-thanks {
  animation: show-form-thanks 0.5s forwards;
  animation-delay: 0.75s;
}

@keyframes show-form-thanks {
  from {
    opacity: 0;
    transform: translateY(-20px);
    z-index: ${ Variables.zIndex01 };
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    z-index: ${ Variables.zIndex03 };
  }
}  
`