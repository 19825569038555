import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0006_App.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class RideSpotPage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="blue"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>In September, PeopleForBikes announced our new Ride Spot platform, an easy-to-use tool for finding and sharing great bike rides.</h4>,
          <Text key={ getRandomNumber(1,1000) }>What keeps people from riding more often? We commissioned research to find the answer to this question and found that not knowing where to ride safely, who to ride with or how to fix a bike topped the list. </Text>,
          <Image key={ getRandomNumber(1,1000) } fluid={ this.props.data.one.childImageSharp.fluid } />,
          <Text key={ getRandomNumber(1,1000) }>Ride Spot reduces these barriers and helps people discover great bike rides, offering resources that include a search feature to match riders with ideal routes, a library of video tutorials on bike maintenance and riding techniques, and social tools to connect users to fun local events and challenges. </Text>,
          <Text key={ getRandomNumber(1,1000) }>More than 400 bicycle retailers are participating in the beta testing phase of Ride Spot; the program will be available to individual riders in April 2019. <a href="https://www.peopleforbikesridespot.org/" target="_blank" rel="noopener noreferrer">Learn more and sign up by visiting the Ride Spot website</a>.</Text>
        ]}
        button="Technology"
        imgalt="Preview of PeopleForBikes Ride Spot app for Retailers"
        imgsrc={ Hero }
        nextpage="/community-grants"
        previouspage="/bike-by-number"
        title="Helping new bike riders discover great routes"
      />
    )
  }
}

export const draftQuery = graphql`
  query {
    one: file(relativePath: { eq: "story-images/PFB_App.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`