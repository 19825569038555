import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0009_Draft.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class DraftPage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="blue"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>Our DRAFT events inspired new, active advocacy champions within the bicycle industry.</h4>,
          <Text key={ getRandomNumber(1,1000) }>DRAFT events feature a series of speeches, announcements and conversations that allow business leaders, product developers, tech innovators, advocates, artists and more to share big ideas—all while enjoying delicious craft beer.</Text>,
          <Image key={ getRandomNumber(1,1000) } fluid={ this.props.data.draftTwo.childImageSharp.fluid } />,
          // <Image key={ getRandomNumber(1,1000) } fluid={ this.props.data.draftOne.childImageSharp.fluid } />,
          <Text key={ getRandomNumber(1,1000) }><strong>In 2018, we hosted 36 draft events in 31 cities</strong>, welcoming thousands of business people to connect and discover new ways to become more active within the bike industry. </Text>,
          <Text key={ getRandomNumber(1,1000) }>Our major partner hosts this year included: QBP, Giant, Bicycle Leadership Conference, Red Bull, Felt, RAGBRAI, Outerbike, BikeNWA, New Belgium Brewing, Oskar Blues Brewing, PlacesForBikes Conference, Brooklyn Bike Co., Pure Cycles, Camber Outdoors and many more local advocacy organizations and bike shops. </Text>,
          <Text key={ getRandomNumber(1,1000) }>Learn more about our DRAFT events — including where you can attend an upcoming event or how to host a meetup in your city — by visiting <a href="https://peopleforbikes.org/draft/" target="_blank" rel="noopener noreferrer">our DRAFT homepage</a>.</Text>
        ]}
        button="Business"
        imgalt="Beer representing DRAFT PeopleForBike events around the country"
        imgsrc={ Hero }
        previouspage="/places-for-bikes-city-rating"
        nextpage="/vote-for-bikes"
        title="Activating the industry"
      />
    )
  }
}

export const draftQuery = graphql`
  query {
    draftOne: file(relativePath: { eq: "story-images/PFB_Draft_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    draftTwo: file(relativePath: { eq: "story-images/PFB_Draft_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`