import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0007_Capitol.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class CongressionalPage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="gray"
        body={[
          <h4 key={ getRandomNumber(1,1000) }><em>“Putting people in the saddle is the first step in getting the conversation about bike infrastructure started.”</em> — Steve Hawkins, Saris Cycling Group and Congressional Bike Fest attendee</h4>,
          <Text key={ getRandomNumber(1,1000) }>In May, PeopleForBikes hosted the second annual Congressional Bike Fest in Washington, D.C. Bike industry members joined PeopleForBikes employees to interface with congressional staffers closely involved in policy and funding decisions that affect bicycling. </Text>,
          <Image key={ getRandomNumber(1,1000) } fluid={ this.props.data.one.childImageSharp.fluid } />,
          <Text key={ getRandomNumber(1,1000) }>This year’s reception welcomed 450 attendees — including representatives from 20 different bike brands. While the event served to call attention to the robust network of bicycle infrastructure in the nation’s capital and discuss upcoming policy items, it also provided an opportunity to showcase some of the latest bikes and gear.</Text>,
          <Text key={ getRandomNumber(1,1000) }>“Infrastructure is crucial to growing the number of people who ride,” said U.S. Representative Daniel Lipinski (D-IL-3) during the Bike Fest. Lipsinki is an avid road biker and member of the House Transportation and Infrastructure Committee.</Text>,
          <Text key={ getRandomNumber(1,1000) }>During the last fiscal year, the federal government invested $916 million in 1,123 bike projects.</Text>,
          <Text key={ getRandomNumber(1,1000) }>Read the full recap of the 2018 Congressional Bike Fest <a href="https://peopleforbikes.org/blog/peopleforbikes-holds-second-annual-congressional-bike-fest/" target="_blank" rel="noopener noreferrer">here</a>.</Text>,
        ]}
        button="Advocacy"
        imgalt="US Capitol building icon"
        imgsrc={ Hero }
        nextpage="/fighting-tariffs"
        previouspage="/big-jump"
        title="Connecting with Congress"
      />
    )
  }
}

export const draftQuery = graphql`
  query {
    one: file(relativePath: { eq: "story-images/PFB_Congressional.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`