import React from 'react'
import { Link } from 'gatsby'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import logo from '../../images/pfb-logo.png'
import financials from '../../images/2018_PFB_Financials_v05.pdf'

const PFBLogo = styled.img`
  width: 212px;
  margin: 0;
`
const PFBNavContainer = styled.nav`
  align-items: center;
  background: #002c40;
  color: #fff;
  display: flex;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  flex-direction: column;
  height: 90px;
  padding: 10px 25px;
  top: 0;

  @media (min-width: ${props => props.theme.xs}) {
    flex-direction: row;
    height: 75px;
    justify-content: space-between;
  }
`
const PFBNavItemContainer = styled.ul`
  display: flex;
  list-style: none;
  margin-top: 10px;
  padding: 0; 

  @media (min-width: ${props => props.theme.xs}) {
    margin: 0;
  }
`
const PFBNavItem = styled.li`
  margin-bottom: 0;
  padding: 0 8px;

  &:first-child:after {
    content: " | ";
    padding-left: 12px;
  }
`

const PFBNavItemLink = styled.a`
  &, &:visited, &:focus {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`

class Header extends React.Component {
  logger() {
    // Detect each click of the financial PDF
    ReactGA.event({
      category: 'Financial Download',
      action: 'User clicked link to view financials'
    })
  }

  render() {
    return (
      <div>
        <PFBNavContainer>
          <Link to="/milestones">
            <PFBLogo 
              src={ logo } 
              alt="People for Bikes Logo" 
            />
          </Link>
            
          <PFBNavItemContainer>
            <PFBNavItem>
              <PFBNavItemLink
                href="https://peopleforbikes.org/shortsignup/" 
                target="_blank"
                rel="noopener noreferrer">Join Us
                </PFBNavItemLink>
            </PFBNavItem>
            <PFBNavItem>
              <PFBNavItemLink  
                href={financials} 
                onClick={this.logger} 
                target="_blank" 
                rel="noopener noreferrer"
                id="pfb-financials-pdf"
                >
                Financials
              </PFBNavItemLink>
            </PFBNavItem>
          </PFBNavItemContainer>
        </PFBNavContainer>
      </div>
    )
  }
}
export default Header