import React from 'react'
import styled from 'styled-components'

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0008_Map.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}




export default class PlacesForBikesPage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="red"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>In May, PeopleForBikes released the first-ever PlacesForBikes City Ratings, ranking 485 U.S cities and identifying the best places for bicycling. Find out how well your city rated <a href="https://cityratings.peopleforbikes.org/" target="_blank" rel="noopener noreferrer">here</a>.</h4>,
          <Text key={ getRandomNumber(1,1000) }>The <a href="https://cityratings.peopleforbikes.org/" target="_blank" rel="noopener noreferrer">PlacesForBikes City Ratings</a> (scored on a scale from one to five stars) are <a href="https://cityratings.peopleforbikes.org/methodology/" target="_blank" rel="noopener noreferrer">based on five factors</a>: Ridership, Safety, Network, Reach and Acceleration. The ratings include publicly available data from government sources and new data gathered from our:</Text>,
          <h2 key={ getRandomNumber(1,1000) }>Community Survey</h2>,
          <Text key={ getRandomNumber(1,1000) }>This survey offers a way for local bike riders and bike businesses to provide feedback on how well their community is supporting bike riding. For the 2018 City Ratings, we collected 39,076 surveys from riders across the nation. </Text>,
          <h2 key={ getRandomNumber(1,1000) }>City Snapshot</h2>,
          <Text key={ getRandomNumber(1,1000) }>This short form is completed by city staff or elected officials and provides concise information about recently completed or planned bike infrastructure in their community. For the 2018 City Ratings, 81 cities completed a City Snapshot. </Text>,
          <h2 key={ getRandomNumber(1,1000) }>Bicycle Network Analysis</h2>,
          <Text key={ getRandomNumber(1,1000) }>This powerful data visualization tool demonstrates how well a city or town’s bike facilities connect people to the places they want to go on a complete, connected and comfortable bike network. It also helps city leaders to understand and address network shortcomings. Find out how well your community’s bike network connects people to the places they want to go <a href="https://bna.peopleforbikes.org/#/" target="_blank" rel="noopener noreferrer">here</a>.</Text>
        ]}
        button="City Resources"
        imgalt="Map icon for PlacesForBikes initiative"
        imgsrc={ Hero }
        nextpage="/draft-meetups"
        previouspage="/ebike-laws"
        title="Showing cities where they stand"
      />
    )
  }
}