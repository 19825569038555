import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import Milestone from '../components/Milestones'
import Hero from '../images/main-art/PFB__0003_Chart.png'

const Text = styled.p`
  line-height: 1.8;
  font-size: 18px;
  margin: 1em 0;

  &:last-child {
    margin-bottom: 10vh;
  }

  @media (min-width: ${props => props.theme.xs} ) {
    font-size: 22px;
  }
`

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default class BikeByNumberPage extends React.Component {
  render() {    
    return (  
      <Milestone
        bgcolor="red"
        body={[
          <h4 key={ getRandomNumber(1,1000) }>Our research team uses data to track progress on bike-related metrics and provide communities with tools to quickly and effectively grow bicycling. </h4>,
          <h2 key={ getRandomNumber(1,1000) }>Ridership</h2>,
          <Text key={ getRandomNumber(1,1000) }>Measuring the number of people who ride bikes in the U.S. isn’t straightforward or easy. In 2014, PeopleForBikes launched the first-of-its-kind study to capture data on all types of bike riding. We just completed a third round of data collection on this study and will have sufficient data to map trend lines in 2020, following round four.</Text>,
          <Image key={ getRandomNumber(1,1000) } fluid={ this.props.data.one.childImageSharp.fluid } />,
          <Text key={ getRandomNumber(1,1000) }>In our 10 Big Jump cities, we are working with national expert Dr. Krista Nordback to develop programs that use automated and manual counters to estimate the number of bike trips taken. Based on that work, we produced a <a href="http://www.pedbikeinfo.org/pdf/PBIC_Infobrief_Counting.pdf" target="_blank" rel="noopener noreferrer">guidebook</a> designed to help communities across the nation do the same.</Text>,
          <h2 key={ getRandomNumber(1,1000) }>Economic benefits of bicycling</h2>,
          <Text key={ getRandomNumber(1,1000) }>In partnership with the Walton Family Foundation and BikeNWA, we conducted a comprehensive study of the economic benefits of bicycling in Northwest Arkansas. We released the full report in March 2018; then, based on that study, we developed a set of <a href="https://peopleforbikes.org/economictemplates/" target="_blank" rel="noopener noreferrer">six measurement templates</a> that any community can use to measure the benefits of bicycling locally.</Text>
        ]}
        button="Research"
        imgalt="Graph representing people for bikes work on bike data"
        imgsrc={ Hero }
        nextpage="/meet-ride-spot"
        previouspage="/vote-for-bikes"
        title="What gets measured gets done: Better data to grow the industry"
      />
    )
  }
}

export const draftQuery = graphql`
  query {
    one: file(relativePath: { eq: "story-images/PFB_Data.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`