import React from 'react'
import Helmet from 'react-helmet'

import icon from '../../images/logo.png'
import socialBanner from '../../images/PFB_2018_social.jpg'

const Metadata = () => (
  <div>
    <Helmet>
      <title>People for Bikes 2018 Annual Report</title>

      <meta name='description' content="Learn more about how we worked to make bicycling better in 2018." />
      <meta name='keywords' content='bicycles, advocacy, E-bikes, e-bikes, thatsmychallenge, take a brake, bikescansolvethat, emtb, prosforbikes, big jump project' />
      <link rel="canonical" href="https://2018.peopleforbikes.org" />

      <meta property='og:image' content={ socialBanner } />
      <meta property='og:locale' content='en_US' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content='PeopleforBikes 2018 Annual Report' />
      <meta property='og:description' content="Learn more about how we worked to make bicycling better in 2018." />
      <meta property='og:url' content='https://2018.peopleforbikes.org' />
      <meta property='og:updated_time' content='2019-01-31' />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@peopleforbikes' />
      <meta name='twitter:title' content='PeopleforBikes 2018 Annual Report' />
      <meta name='twitter:description' content="Learn more about how we worked to make bicycling better in 2018." />
      <meta name='twitter:image' content={ socialBanner } />
      <meta name='twitter:image:alt' content='PeopleforBikes 2018 Annual Report, featuring an overview of all PFB initiatives during 2018.' />
      <meta name="twitter:url" content="https://2018.peopleforbikes.org" />

      <script type="application/ld+json">{`
        "@context": "http://schema.org",
        "@type":"Website",
        "name":"2018 People for Bikes Annual Report",
        "url":"https://2018.peopleforbikes.org/"
      `}</script>
      <script type="application/ld+json">{`
        "@context":"http://schema.org",
        "@type":"Organization",
        "name":"People for Bikes",
        "url":"http://www.peopleforbikes.org/",
        "sameAs":[ 
          "https://www.facebook.com/PeopleForBikes",
          "https://twitter.com/peopleforbikes",
          "https://www.linkedin.com/company/329031"
        ]
      `}</script>

      <link rel="apple-touch-icon" href={ icon } />
      <link rel="icon" type="image/png" href={ icon } />

      <link rel="stylesheet" href="https://unpkg.com/normalize.css@8.0.1/normalize.css" />
      <link rel="stylesheet" href="https://use.typekit.net/utt5xdp.css" />
    </Helmet>
  </div>
)

export default Metadata
